import Vue from "vue";

import SearchFilter from "components/SearchFilter";
import ProcessorLogicModal from "modules/system-maintenance/ScheduledProcessors/modals/ProcessorLogicModal";

import ScheduledProcessor from "store/models/ScheduledProcessor";
import ScheduledProcessorTxn from "store/models/ScheduledProcessorTxn";

export default {
  name: "ScheduledProcessors",
  components: {
    ProcessorLogicModal,
  },
  data() {
    return {
      isLoading: {
        initial: false,
        dataTable: false,
        searchFilter: false,
      },
      modalVisibility: {
        runHistory: false,
        processorLogic: false,
      },
      runHistoryOptions: {
        modalModelInstance: ScheduledProcessorTxn,
        initialFilter: {
          processorId: "",
          archive: true,
        },
      },
      searchFilter: null,
      filter: "",
      totalCount: 0,
      items: [],
      processorLogicString: "",
      columns: {
        scheduledProcessor: [
          {
            name: "processorName",
            align: "left",
            text: "Processor Name",
            field: "processorName",
            sortable: true,
          },
          {
            name: "nextRun",
            align: "left",
            text: "Next Run",
            field: "nextRun",
          },
          {
            name: "lastRun",
            align: "left",
            text: "Last Run",
            field: "lastRun",
          },
          {
            name: "status",
            align: "center",
            text: "Last Run Status",
            field: "status",
            isStatusColumn: true,
          },
          {
            name: "frequency",
            align: "left",
            text: "Frequency",
            field: "frequency",
          },
          {
            name: "action",
            text: "Action",
            field: "action",
            align: "center",
          },
        ],
        scheduledProcessorTxn: [
          {
            name: "dateRun",
            align: "left",
            text: "Date Run",
            field: "dateRun",
          },
          {
            name: "status",
            align: "left",
            text: "Status",
            field: "status",
            isStatusColumn: true,
          },
          {
            name: "accessionId",
            align: "left",
            text: "Accession ID",
            field: "accessionId",
          },
        ],
      },
      limitData: {
        scheduledProcessor: [
          this.buildSubQuery("name", ["label"], false),
          "nextRunDate",
          this.buildSubQuery(
            "lastProcessorTxn",
            [this.buildSubQuery("status", ["enum"], false), "lastModified"],
            false
          ),
          this.buildSubQuery("frequency", ["label"], false),
          "processorLogic",
          "totalCount",
        ],
        scheduledProcessorTxn: [
          "lastModified",
          "totalCount",
          this.buildSubQuery("status", ["label"], false),
          "description",
        ],
      },
    };
  },
  methods: {
    async fetch() {
      try {
        this.isLoading.dataTable = true;
        await this.searchFilter.filterSearchModel(10);
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      } finally {
        this.isLoading.dataTable = false;
      }
    },
    viewRunHistory(id) {
      this.runHistoryOptions.processorId = id;
      this.modalVisibility.runHistory = true;
    },
    viewProcessorLogic(processorLogic) {
      this.processorLogicString = processorLogic;
      this.modalVisibility.processorLogic = true;
    },
    renderScheduledProcessorTxns(items) {
      return items.map((item) => {
        const { totalCount, lastModified, status, description } = item;

        return {
          dateRun: lastModified
            ? this.$options.filters.getTimeDate(
                lastModified,
                "MM/DD/YYYY hh:mm A",
                true
              ).zone
            : "",
          status: status?.label || "failed",
          accessionId: JSON.parse(description)?.accessionId || "",
          totalCount,
        };
      });
    },
    closeModal(type) {
      this.modalVisibility[type] = false;
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      this.isLoading.initial = true;

      // extend search filter as component
      const theSearchFilter = Vue.extend(SearchFilter);

      // create search filter component instance
      this.searchFilter = new theSearchFilter({
        propsData: {
          modelInstance: ScheduledProcessor,
          limitData: this.limitData.scheduledProcessor,
          relativeUrl: "/scheduled-processor/all",
          queryName: "vbsScheduledProcessors",
          isLoading: this.isLoading.searchFilter,
        },
        parent: this.$refs.container.__vue__,
      });

      // listen to search result emitted
      this.searchFilter.$on("searchResults", (data) => {
        if (data.length) {
          this.items = data.map((item, index) => {
            if (index == 0) this.totalCount = item.totalCount;

            const {
              id,
              name,
              nextRunDate,
              lastProcessorTxn,
              frequency,
              processorLogic,
            } = item;

            return {
              id,
              processorName: name?.label || "--",
              nextRun: nextRunDate
                ? this.$options.filters.getTimeDate(
                    nextRunDate,
                    "MM/DD/YYYY hh:mm A",
                    true
                  ).zone
                : "--",
              lastRun: lastProcessorTxn.lastModified
                ? this.$options.filters.getTimeDate(
                    lastProcessorTxn.lastModified,
                    "MM/DD/YYYY hh:mm A",
                    true
                  ).zone
                : "--",
              status:
                lastProcessorTxn.status.enum === "IN_PROGRESS"
                  ? lastProcessorTxn.status.enum.replace("_", "-").toLowerCase()
                  : lastProcessorTxn.status.enum.toLowerCase(),
              frequency: frequency?.label || "--",
              processorLogic,
              additionalMoreCommands: [
                {
                  label: "View Processor Logic",
                  action: (property) => this.viewProcessorLogic(property),
                  returnProperty: "processorLogic",
                },
              ],
            };
          });
        } else {
          this.items = [];
          this.totalCount = 0;
        }
      });

      // listen to loading emitted
      this.searchFilter.$on("loading", (bool) => {
        this.isLoading.dataTable = bool;
      });

      await this.fetch();

      this.isLoading.initial = false;
    });
  },
};
