import axios from "axios";

export default {
  name: "ProcessorLogicModal",
  props: {
    visibility: Boolean,
    processorLogic: { required: true, type: String },
  },
  data() {
    return {
      htmlString: "",
      loading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
  mounted() {
    this.$nextTick(async () => {
      try {
        this.loading = true;
        if (this.processorLogic) {
          const result = await axios.get(this.processorLogic);
          if (result.data) this.htmlString = result.data;
        }
      } catch (err) {
        this.showNotifyMessage({
          message: "A problem has occured while fetching data.",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    });
  },
};
